import Meta from "../components/Meta";
import loading from "../assets/images/480.gif";

const UnderConstruction = () => {
  return (
    <>
      <Meta />
      <div className="App">
        <header className="App-header">
          <img src={loading} className="App-logo" alt="loading" />
          <p>Amidoux.be is onder constructie!</p>
        </header>
      </div>
    </>
  );
};

export default UnderConstruction;
