import Meta from "../components/Meta";

const Home = () => {
  return (
    <>
      <Meta title={"Home"} />
      Home
    </>
  );
};

export default Home;
