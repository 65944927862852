import Meta from "../components/Meta";

const About = () => {
  return (
    <>
      <Meta title={"Over ons"} />
    </>
  );
};

export default About;
