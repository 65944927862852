// src/components/Meta/Meta.tsx
import React from "react";
import { Helmet } from "react-helmet";

interface MetaProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
}

const Meta: React.FC<MetaProps> = ({
  title = "",
  description = "Welkom op de website van Amidoux, een hondenfokker in Brugge gespecialiseerd in Labradors. Ontdek onze honden, fokprogramma en meer.",
  keywords = "Amidoux, hondenfokker, Brugge, Labradors, honden, fokprogramma, puppy's, labrador retriever",
  image = "https://www.amidoux.be/images/home-banner.jpg",
  url = "https://www.amidoux.be/",
}) => {
  return (
    <Helmet>
      <title>Amidoux{title !== "" ? " - " + title : title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Amidoux - Hondenfokker" />
      <meta
        property="og:title"
        content={"Amidoux: " + title !== "" ? " - " + title : title}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta
        name="twitter:title"
        content={"Amidoux" + title !== "" ? " - " + title : title}
      />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default Meta;
