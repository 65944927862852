import { NavLink } from "react-router-dom";

const TopBar = () => {
  return (
    <div id="topbar">
      <nav>
        <ul>
          <li>
            <NavLink to={`./`}>Home</NavLink>
          </li>
          <li>
            <NavLink to={`./about`}>About</NavLink>
          </li>
          <li>
            <NavLink to={`./contact`}>Contact</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TopBar;
