import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/global.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routeConfig from "./routes/routes";
import Meta from "./components/Meta";
import UnderConstruction from "./pages/UnderConstruction";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([routeConfig]);

if (process.env.REACT_APP_MODE === "DEVELOPMENT") {
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <UnderConstruction />
    </React.StrictMode>
  );
}
