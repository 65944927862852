import { Outlet } from "react-router-dom";

import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import Meta from "./components/Meta";

const App = () => {
  return (
    <>
      <Meta />
      <TopBar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default App;
