import App from "../App";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Error from "../pages/Error";
import Home from "../pages/Home";

const routeConfig = {
  path: "/",
  element: <App />,
  errorElement: <Error />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: "about",
      element: <About />,
    },
    /*{
      path: "contact",
      element: <Contact />,
    },*/
  ],
};

export default routeConfig;
