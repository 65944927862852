import { Button } from "@mui/material";
import { NavLink, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error: any = useRouteError();

  return (
    <div id="error-page">
      <h1>Woefs!</h1>
      <p>Er heeft zich een fout voorgedaan: </p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <NavLink to={"./"}>Terug</NavLink>
    </div>
  );
};

export default ErrorPage;
